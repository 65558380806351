<template>
    <div class="bg re_sizing">
        <div class="title re_relative re_flex_center re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span>提现服务协议</span>
        </div>
        <div class="content">
            <iframe :src="ruleInfo.agreementUrl" scrolling="auto" frameborder="0" class="trend-container2"></iframe>
        </div>
    </div>
</template>

<script setup>
import { getDrawConfig } from './api.js'
import { ref, onMounted } from 'vue';
const ruleInfo = ref({});
const goBack = () => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        try {
            android.finish()
        } catch (error) {
            appJsImp.finish()
        }
    }
    if (isiOS) {
        window.webkit.messageHandlers.AppModel.postMessage('finish')
    }
}
onMounted(() => {
    getDrawConfig().then(res => {
        if (res.code == 1) {
            ruleInfo.value = res.data
        }
    }).catch(err => {
        console.log(err)
    })
})
</script>

<style lang="less" scoped>
.bg {
    padding-top: 50px;
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    .title {
        width: 100%;
        font-weight: 600;
        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
    .content{
        padding: 15px 0px 0;
        .trend-container2 {
            width: 100vw;
            height: calc(100vh - 6rem);
        }
    }
}
</style>